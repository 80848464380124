import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ShoppingCartOutlined,
  ExpandMore,
  PlayCircleOutline,
  Menu as MenuIcon,
} from "@mui/icons-material";

function ResponsiveAppBar({ setAppBarHeight }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const appBarRef = useRef(null);

  useEffect(() => {
    if (appBarRef.current) {
      // Remonte la hauteur initiale
      setAppBarHeight(appBarRef.current.offsetHeight);

      const handleResize = () => {
        if (appBarRef.current) {
          setAppBarHeight(appBarRef.current.offsetHeight);
        }
      };

      // Met à jour la hauteur lors du redimensionnement
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [setAppBarHeight]);

  const handleDiscoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDiscoverClose = () => {
    setAnchorEl(null);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const discoverLinks = [
    {
      label: "À propos",
      url: "https://mycover-up.fr/decouvrir-mycoverup/",
    },
    {
      label: "Nos réalisations",
      url: "https://mycover-up.fr/nos-realisations/",
    },
    {
      label: "Procédé de fabrication de nos caches",
      url: "https://mycover-up.fr/procede-de-fabrication-de-nos-caches/",
    },
    {
      label: "Composite aluminium : le matériau de nos caches",
      url: "https://mycover-up.fr/le-composite-aluminium-le-materiau-de-nos-caches/",
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        <ListItem>
          <Button
            variant="contained"
            color="primary"
            href="https://configurateur.mycover-up.fr/"
            sx={{
              backgroundColor: "#007ACE",
              borderRadius: "20px",
              color: "#fff",
              textTransform: "none",
              width: "100%",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#007ACE",
              },
            }}
          >
            Créer mon cache
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            href="https://mycover-up.fr/support-mycover-up/#montage"
            sx={{
              backgroundColor: "#f4f4f4",
              borderRadius: "20px",
              color: "#000",
              textTransform: "none",
              width: "100%",
              "&:hover": {
                backgroundColor: "#007ACE",
              },
            }}
          >
            Monter mon cache ▶️
          </Button>
        </ListItem>
        <ListItem>
          <ListItemText primary="Découvrir myCover'Up" />
        </ListItem>
        {discoverLinks.map((link) => (
          <ListItem
            component="a"
            href={link.url}
            key={link.label}
            sx={{ 
              pl: 4, 
              "&:visited": { color: "#007bff" }, 
              fontFamily: '"Fira Sans", Sans-serif',
              fontSize: "14px",
              fontWeight: "regular" 
            }}
          >
            <ListItemText primary={link.label} />
          </ListItem>
        ))}
        <Divider />
        <ListItem
          component="a"
          sx={{ "&:visited": { color: "#0000" } }}
          href="https://mycover-up.fr/mon-compte/"
        >
          <ListItemText primary="Mon compte" />
        </ListItem>
        <ListItem
          component="a"
          sx={{ "&:visited": { color: "#0000" } }}
          href="https://mycover-up.fr/contact/"
        >
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem
          component="a"
          sx={{ "&:visited": { color: "#0000" } }}
          href="https://mycover-up.fr/panier/"
        >
          <IconButton href="https://mycover-up.fr/panier/" sx={{ color: "#007bff" }}>
            <Badge badgeContent={0} color="secondary">
              <img
                src="/icons/cart_icon.svg"
                alt="Cart Icon"
                style={{ width: 24, height: 24 }}
              />
            </Badge>
          </IconButton>
          <ListItemText primary="Panier" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar ref={appBarRef} position="fixed" 
    sx={{ 
      backgroundColor: "white", 
      color: "black" ,     
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.4)",
    }}>
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          alignItems: "center",
          //minHeight: { xs: "7.6vh", md: "8.5vh" },
          justifyContent: "center",
          position: "relative",
          minHeight: "inherit",
          height: "75px",
  
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
            }}
          >
            <Box
              component="a"
              href="https://mycover-up.fr/"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src="/logo-horizontal-couleur.png"
                alt="Logo"
                sx={{
                  height: { xs: "4vh", sm: "4vh", md: "4vh" },
                  maxWidth: "150px",
                  objectFit: "contain",
                }}
              />
            </Box>

            {/* burger */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        ) : (
          <>
          <Box
            sx={{
              display: "flex",
              width: "90%",
              alignItems: "center",
              justifyContent: "space-between",
              
              position: "relative", // Nécessaire pour positionner le panier en mode absolu
            }}
          >
            <Box
              component="a"
              href="https://mycover-up.fr/"
              sx={{
                display: "flex",
                alignItems: "center",
                width:"17%"
              }}
            >
              <Box
                component="img"
                src="/logo-horizontal-couleur.png"
                alt="Logo"
                sx={{
                  width: "100%", // L'image prend toute la largeur du conteneur
                  height: "auto", // La hauteur s'adapte proportionnellement
                  objectFit: "contain", // Garde les proportions
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                flexGrow: 1, // Prend tout l'espace restant pour les boutons
              }}
            >
              {/* Create my cover button */}
              <Button
                variant="contained"
                color="primary"
                href="https://configurateur.mycover-up.fr/"
                sx={{
                  backgroundColor: "#007AC3",
                  borderRadius: "20px",
                  color: "#fff",
                  boxShadow: "none !important",
                  textTransform: "none",
                  fontFamily: '"Fira Sans", Sans-serif',
                  fontSize: "14px",
                  fontWeight: "regular",
                  "&:hover": {
                    boxShadow: "none !important",
                    backgroundColor: "#fff",
                    color: "#007ACE",
                  },
                }}
              >
                Créer mon cache
              </Button>

              {/* Mount my cover button */}
              <Button
                variant="contained"
                href="https://mycover-up.fr/support-mycover-up/#montage"
                sx={{
                  backgroundColor: "#f4f4f4",
                  borderRadius: "20px",
                  color: "#000",
                  boxShadow: "none !important",
                  textTransform: "none",
                  fontFamily: '"Fira Sans", Sans-serif',
                  fontSize: "13px",
                  fontWeight: "600",
                  "&:hover": {
                    backgroundColor: "#007ACE",
                    boxShadow: "none !important",
                    color: "#fff",
                  },
                }}
              >
                Monter mon cache ▶️
              </Button>
              
              <Box
              sx={{
                display: "flex",
                gap: 0,
                justifyContent: "center",
               // Prend tout l'espace restant pour les boutons
              }}>
              {/* Discover myCover'Up button */}
              <Button
                disableRipple
                onClick={handleDiscoverClick}
                endIcon={<ExpandMore />}
                sx={{
                  textTransform: "none",
                  color: "#000",
                  fontFamily: '"Fira Sans", Sans-serif',
                  fontSize: "13px",
                  fontWeight: "600",
                  backgroundColor: "transparent", // Pas de fond par défaut
                  "&:hover": {
                    backgroundColor: "transparent", // Pas de fond au survol
                    color: "#007ACE", // Changement de couleur du texte
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "3px",
                    backgroundColor: "transparent",
                    transform: "scaleX(0)",
                    transformOrigin: "center",
                    transition: "transform 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    backgroundColor: "#007ACE",
                    transform: "scaleX(1)",
                  },
                }}
              >
                Découvrir myCover'Up
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleDiscoverClose}
                MenuListProps={{
                  "aria-labelledby": "discover-button",
                }}
              >
                {discoverLinks.map((link) => (
                  <MenuItem
                    key={link.label}
                    onClick={handleDiscoverClose}
                    component="a"
                    href={link.url}

                    sx={{
                      fontFamily: '"Fira Sans", Sans-serif',
                      fontSize: "14px",
                      fontWeight: "regular",
                      "&:hover": {
                        color: "#007bff",
                      },
                    }}
                  >
                    {link.label}
                  </MenuItem>
                ))}
              </Menu>
              {/* Bouton "Mon compte" */}
              <Button
                disableRipple
                href="https://mycover-up.fr/espace-client/"
                sx={{
                  textTransform: "none",
                  color: "#000",
                  fontFamily: '"Fira Sans", Sans-serif',
                  fontSize: "13px",
                  fontWeight: "600",
                  backgroundColor: "transparent", // Pas de fond par défaut
                  "&:hover": {
                    backgroundColor: "transparent", // Pas de fond au survol
                    color: "#007ACE", // Changement de couleur du texte
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "3px",
                    backgroundColor: "transparent",
                    transform: "scaleX(0)",
                    transformOrigin: "center",
                    transition: "transform 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    backgroundColor: "#007ACE",
                    transform: "scaleX(1)",
                  },
                }}
              >
                Espace Client
              </Button>

              {/* Bouton "Contact" */}
              <Button
                disableRipple
                href="https://mycover-up.fr/contact/"
                sx={{
                  textTransform: "none",
                  color: "#000",
                  fontFamily: '"Fira Sans", Sans-serif',
                  fontSize: "13px",
                  fontWeight: "600",
                  backgroundColor: "transparent", // Pas de fond par défaut
                  "&:hover": {
                    backgroundColor: "transparent", // Pas de fond au survol
                    color: "#007ACE", // Changement de couleur du texte
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "3px",
                    backgroundColor: "transparent",
                    transform: "scaleX(0)",
                    transformOrigin: "center",
                    transition: "transform 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    backgroundColor: "#007ACE",
                    transform: "scaleX(1)",
                  },
                }}
              >
                Contact
              </Button>
              <Button
                disableRipple
                href="https://mycover-up.fr/bons-plans/"
                sx={{
                  textTransform: "none",
                  color: "#000",
                  fontFamily: '"Fira Sans", Sans-serif',
                  fontSize: "13px",
                  fontWeight: "600",
                  backgroundColor: "transparent", // Pas de fond par défaut
                  "&:hover": {
                    backgroundColor: "transparent", // Pas de fond au survol
                    color: "#007ACE", // Changement de couleur du texte
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "3px",
                    backgroundColor: "transparent",
                    transform: "scaleX(0)",
                    transformOrigin: "center",
                    transition: "transform 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    backgroundColor: "#007ACE",
                    transform: "scaleX(1)",
                  },
                }}
              >
                Bons plans
              </Button>
            </Box>
            </Box>


          </Box>
          <IconButton
          href="https://mycover-up.fr/mon-panier/"
          sx={{
            color: "#007bff",
            position: "absolute", // Sort du flux normal
            right: "1%", // Collé à droite
            top: "50%", // Centré verticalement
            transform: "translateY(-50%)", // Ajuste le centrage
          }}
        >
          <Badge badgeContent={0} color="secondary">
            <img
              src="/icons/cart_icon.svg"
              alt="Cart Icon"
              style={{ width: 24, height: 24 }}
            />
          </Badge>
        </IconButton>
        </>
        )}
        
      </Toolbar>

      {/* Drawer for mobile menu */}
      <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default ResponsiveAppBar;
