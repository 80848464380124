import React, { useState, useEffect } from "react";
import DimensionsForm from "./DimensionsForm";
import PatternForm from "./PatternForm";
import TypeForm from "./TypeForm";
import TextureForm from "./TextureForm";
import { FormButtonNext } from "../Stepper/FormButtonNext";
import { FormButtonPrevious } from "../Stepper/FormButtonPrevious";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ConfiguratorForm = ({
  step,
  modelFormData,
  materialFormData,
  patterns,
  textures,
  onModelFormChange,
  onMaterialFormChange,
  onQuotationChange,
  handleStepChange,
}) => {
  const [dimensionsErrorString, setDimensionsErrorString] = useState(null);
  const [dimensionsNeedDevis, setDimensionsNeedDevis] = useState(false);
  const [isDimensionsError, setIsDimensionsError] = useState(false); 
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [previousL, setPreviousL] = useState(0)
  const [previousH, setPreviousH] = useState(0)
  const [previousP1, setPreviousP1] = useState(0)
  const [previousP2, setPreviousP2] = useState(0)
  const [previousType, setPreviousType] = useState(null)
  const [previousBottom, setPreviousBottom] = useState(null)





  useEffect(() =>{
    handleQuotationNeed()
  },[modelFormData])

  let maxStep;
  if (isXs) {
    maxStep = 2;
  } else if (isSm || isMd) {
    maxStep = 3;
  } else {
    maxStep = 3;
  }

  const setTabChange = (newValue) => {
    setSelectedTab(newValue);

  };

  const resetErrors = () => {
    setDimensionsErrorString(null);
    setDimensionsNeedDevis(false);
    setIsDimensionsError(false)
  };

  const handlePatternChange = (event) => {
    onModelFormChange("pattern", event.target.value);
  };

  const handleMaterialChange = (material) => {
    onMaterialFormChange("material", material);
  };

  const handleTypeChange = (type) => {
    onModelFormChange("type", type);
    let hasBottom = modelFormData.bottom;
    if(type != "facade-haut"){
     hasBottom = false;
      onModelFormChange("bottom", false);
    }

    handleLChange(modelFormData.sizeL)
    handleP1Change(modelFormData.sizeP1)
    handleP2Change(modelFormData.sizeP2)
    handleHChange(modelFormData.sizeH, type, hasBottom)
  };

  const handleBottomFaceChange = (isChecked) => {
    onModelFormChange("bottom", isChecked);
    handleLChange(modelFormData.sizeL)
    handleP1Change(modelFormData.sizeP1)
    handleP2Change(modelFormData.sizeP2)
    handleHChange(modelFormData.sizeH, undefined, isChecked)
  };

  const recalculateDimensions = (updatedValues = {}, forceFromRearY = false, forceFromZ = false, forceFromX = false) => {
    const sin2Degrees = Math.sin((2 * Math.PI) / 180);
  
    const newFormData = { ...modelFormData, ...updatedValues };
  
    let frontHeight = 0;
    let sizeL = newFormData.sizeL;
    let sizeH = newFormData.sizeH;
    if(forceFromRearY === false){
      let hFactor = 30;
      if (newFormData.type === "facade-haut" && newFormData.bottom) {
        hFactor = 70;
      } else if (
        newFormData.type === "facade-haut" ||
        newFormData.type === "facade-sol"
      ) {
        hFactor = 40;
      }
      frontHeight = newFormData.sizeH + hFactor;
      if (frontHeight < 500) frontHeight = 500;  
    }
  
    let sizeX = 0;
    if(forceFromX === false){
      sizeX = sizeL + 90; 
    }else{
      sizeX = newFormData.sizeX;
    }

    sizeX = sizeL < 400 ? 400 : sizeX;

    let sizeZ = 0;

    if(forceFromZ === false){
      if (newFormData.sizeP1 + 80 >= newFormData.sizeP2 + 30) {
        sizeZ = newFormData.sizeP1 + 80;
      } else if (newFormData.sizeP2 + 30 > newFormData.sizeP1 + 80) {
        sizeZ = newFormData.sizeP2 + 30;
      }
    }else{
      sizeZ = newFormData.sizeZ;
    }

    sizeZ = sizeZ < 350 ? 350 : sizeZ
    console.log("Size z is : ", sizeZ)

    let rearHeight = Math.ceil(frontHeight + sin2Degrees * (sizeZ - 15));
    if(forceFromRearY === true){
      rearHeight = newFormData.sizeY;
      frontHeight = Math.floor(rearHeight - sin2Degrees * (sizeZ - 15));
    }else if(forceFromZ === true){
      rearHeight = newFormData.sizeY;
      frontHeight = Math.floor(rearHeight - sin2Degrees * (sizeZ - 15));
    }else {

    }
    console.log("calculated front height : ", frontHeight)
    if(!forceFromX) onModelFormChange("frontHeight", frontHeight);

    onModelFormChange("sizeH", sizeH);
    onModelFormChange("sizeL", sizeL);
    onModelFormChange("sizeP1", newFormData.sizeP1);
    onModelFormChange("sizeP2", newFormData.sizeP2);

    onModelFormChange("noClipX", newFormData.sizeL);
    onModelFormChange("noClipY", newFormData.sizeH);
    onModelFormChange("noClipZP1", newFormData.sizeP1);
    onModelFormChange("noClipZP2", newFormData.sizeP2);

    if(forceFromX){
      onModelFormChange("sizeX", sizeX);
      return;
    }

    if(forceFromRearY){
      onModelFormChange("sizeY", rearHeight);
      return;
    }

    if(forceFromZ){
      onModelFormChange("sizeZ", sizeZ);
      return;
    }

    onModelFormChange("sizeX", sizeX);
    onModelFormChange("sizeY", rearHeight);
    onModelFormChange("sizeZ", sizeZ);


  };
  
  const handleLChange = (L) => {
    if(L == previousL) return;
    setPreviousL(L);
    recalculateDimensions({ sizeL: L });
  };
  
  const handleHChange = (H, type, bottom) => {
    if(H == previousH && previousType == type && previousBottom == bottom) return;
    setPreviousH(H);
    setPreviousType(type);
    setPreviousBottom(bottom);
    recalculateDimensions({
      sizeH: H,
      type: type || modelFormData.type,
      bottom: bottom !== undefined ? bottom : modelFormData.bottom,
    });
  };
  
  const handleP1Change = (P1) => {
    if(P1 == previousP1) return;
    setPreviousP1(P1);
    recalculateDimensions({ sizeP1: P1 });
  };

  const handleP2Change = (P2) => {
    if(P2 == previousP2) return;
    setPreviousP2(P2);
    recalculateDimensions({ sizeP2: P2 });
  };
  
  const handleZChange = (Z) => {
    recalculateDimensions({ sizeZ: Z }, false, true);
  };
  
  const handleXChange = (X) => {
    recalculateDimensions({ sizeX: X }, true, false, true);
  };

  const handleRearYChange = (Y) => {
    recalculateDimensions( {sizeY: Y}, true);
};
  

  const handleQuotationNeed = (
    LU = modelFormData.sizeL,
    HU = modelFormData.sizeH,
    P1U = modelFormData.sizeP1,
    P2U = modelFormData.sizeP2,
    H = modelFormData.frontHeight,
    L = modelFormData.sizeX,
    P = modelFormData.sizeZ,
    type = modelFormData.type,
    bottom = modelFormData.bottom,
    noClipX = modelFormData.noClipX,
    noClipY = modelFormData.noClipY,
    noClipZP1 = modelFormData.noClipZP1,
    noClipZP2 = modelFormData.noClipZP2
  ) => {
    resetErrors();
    let errorString = null;
    let error = false;
    let needQuotation = false;
    let noPrice = false;
    if (L > 1350) {
      errorString = "La largeur de votre cache dépasse la limite de notre configurateur.";
      needQuotation = true;
    }

    if (H > 1450) {
      errorString = "La hauteur de votre cache dépasse la limite de notre configurateur.";
      needQuotation = true;
    }

    let noclipErrors = 0

    if (noClipX < 400) {
      errorString = "Attention : la largeur de votre unité ne permet pas un ajustement parfait du cache.";
      noclipErrors = noclipErrors +1
    }

    if (noClipY < 500) {
      errorString = "Attention : la hauteur de votre unité ne permet pas un ajustement parfait du cache.";
      noclipErrors = noclipErrors +1
    }

    if (noClipZP1 < 350 || noClipZP2 < 350) {
      errorString = "Attention : la profondeur de votre unité ne permet pas un ajustement parfait du cache.";
      noclipErrors = noclipErrors +1
    }

    if(noclipErrors > 1){
      errorString = "Les dimensions renseignées ne permettent pas un ajustement parfait.";
    }

    if(selectedTab === 1){
      if (L < LU +85) {
        errorString = "Attention : la largeur choisie ne permettra pas la bonne installation de votre cache et risque d'aboutir au contact entre l'unité et le cache, qu'il faut absolument éviter.";
        error = true;
        needQuotation = false;
      }

      if(type == "facade-haut" && bottom){
        if (H < HU + 60 ) {
          errorString = "Attention : la hauteur choisie permettra l'installation de votre cache mais sous-entend une prise de mesure très précise et une pose particulièrement soignée de votre part.";
        }

        if (H < HU + 45 ) {
          errorString = "Attention : la hauteur choisie ne permettra pas la bonne installation de votre cache dans les conditions normalement prévues.";
          error = true;
        }
      }else if(type == "facade-haut" || type == "facade-sol"){
        if (H < HU + 20 ) {
          errorString = "Attention : la hauteur choisie permettra l'installation de votre cache mais sous-entend une prise de mesure très précise et une pose particulièrement soignée de votre part.";
          needQuotation = false;
        }

        if (H < HU + 5 ) {
          errorString = "Attention : la hauteur choisie ne permettra pas la bonne installation de votre cache dans les conditions normalement prévues.";
          error = true;
          needQuotation = false;
        }
      }else{
        
        if (H < HU + 10 ) {
          errorString = "Attention : la hauteur choisie permettra l'installation de votre cache mais sous-entend une prise de mesure très précise et une pose particulièrement soignée de votre part.";
          needQuotation = false;
        }

        if (H < HU + 5 ) {
          errorString = "Attention : la hauteur choisie ne permettra pas la bonne installation de votre cache dans les conditions normalement prévues.";
          error = true;
          needQuotation = false;
        }
      }


      if (P < P1U + 80 ) {
        errorString = "Attention : la profondeur choisie risque de pénaliser le flux d'air.";
        needQuotation = false;
      }

      if (P < P1U + 50 ) {
        errorString = "Attention : la profondeur choisie ne permettra pas la bonne installation de votre cache et risque d'aboutir au contact entre l'unité et le cache, qu'il faut absolument éviter.";
        error = true;
        needQuotation = false;
      }

      if (P < P2U + 20 ) {
        errorString = "Attention : la profondeur choisie ne permettra pas la bonne installation de votre cache et risque d'aboutir au contact entre les supports et le cache, qu'il faut absolument éviter.";
        error = true;
        needQuotation = false;
      }
    }

    if (H > 2000) {
      errorString = "La hauteur de votre cache dépasse nos limites techniques de fabrication.";
      noPrice = true;
      needQuotation = false;
    }
    
    if (L > 2000) {
      errorString = "La largeur de votre cache dépasse nos limites techniques de fabrication.";
      noPrice = true;
      needQuotation = false;
    }

    if ((L > 2000 && H > 2000) || ((L > 2000 || H > 2000) && P > 2000)) {
      errorString = "Les dimensions de votre cache dépassent nos limites techniques de fabrication.";
      noPrice = true;
      needQuotation = false;
    }else if (P > 2000) {
      errorString = "La profondeur de votre cache dépasse nos limites techniques de fabrication.";
      noPrice = true;
      needQuotation = false;
    }

    if(needQuotation) noPrice = needQuotation;
    onQuotationChange("isNeeded", needQuotation);
    onQuotationChange("noPrice", noPrice);
    setDimensionsErrorString(errorString);
    setIsDimensionsError(error)
    setDimensionsNeedDevis(needQuotation);
  };

  const renderSteps = () => {
    if (isXs) {
      if (step === 0) {
        return (
          <>
            <PatternForm
              selectedPattern={modelFormData.pattern}
              onPatternChange={handlePatternChange}
              patterns={patterns}
            />
            <TextureForm
              selectedMaterial={materialFormData.material}
              onMaterialChange={handleMaterialChange}
              textures={textures}
            />
          </>
        );
      } else if (step === 1) {
        return (
          <TypeForm
            selectedType={modelFormData.type}
            onTypeChange={handleTypeChange}
            bottomFace={modelFormData.bottom}
            onBottomFaceChange={handleBottomFaceChange}
          />
        );
      } else if (step === 2) {
        return (
          <DimensionsForm
            installationType={modelFormData.type}
            sizeL={modelFormData.sizeL}
            sizeH={modelFormData.sizeH}
            sizeP1={modelFormData.sizeP1}
            sizeP2={modelFormData.sizeP2}
            sizeX={modelFormData.sizeX}
            sizeY={modelFormData.sizeY}
            sizeZ={modelFormData.sizeZ}
            frontHeight={modelFormData.frontHeight}
            onSizeLChange={handleLChange}
            onSizeHChange={handleHChange}
            onSizeP1Change={handleP1Change}
            onSizeP2Change={handleP2Change}
            onSizeXChange={handleXChange}
            onRearYChange={handleRearYChange}
            onSizeZChange={handleZChange}
            dimensionsErrorString={dimensionsErrorString}
            dimensionsNeedDevis={dimensionsNeedDevis}
            isDimensionsError={isDimensionsError}
            setTabChange={setTabChange}
            bottom={modelFormData.bottom}
          />
        );
      }
    } else {
      // Pour sm, md, lg, xl
      if (step === 0) {
        return (
          <PatternForm
            selectedPattern={modelFormData.pattern}
            onPatternChange={handlePatternChange}
            patterns={patterns}
          />
        );
      } else if (step === 1) {
        return (
          <TextureForm
            selectedMaterial={materialFormData.material}
            onMaterialChange={handleMaterialChange}
            textures={textures}
          />
        );
      } else if (step === 2) {
        return (
          <TypeForm
            selectedType={modelFormData.type}
            onTypeChange={handleTypeChange}
            bottomFace={modelFormData.bottom}
            onBottomFaceChange={handleBottomFaceChange}
          />
        );
      } else if (step === 3) {
        return (
          <DimensionsForm
            installationType={modelFormData.type}
            sizeL={modelFormData.sizeL}
            sizeH={modelFormData.sizeH}
            sizeP1={modelFormData.sizeP1}
            sizeP2={modelFormData.sizeP2}
            sizeX={modelFormData.sizeX}
            sizeY={modelFormData.sizeY}
            sizeZ={modelFormData.sizeZ}
            frontHeight={modelFormData.frontHeight}
            onSizeLChange={handleLChange}
            onSizeHChange={handleHChange}
            onSizeP1Change={handleP1Change}
            onSizeP2Change={handleP2Change}
            onSizeXChange={handleXChange}
            onRearYChange={handleRearYChange}
            onSizeZChange={handleZChange}
            dimensionsErrorString={dimensionsErrorString}
            dimensionsNeedDevis={dimensionsNeedDevis}
            isDimensionsError={isDimensionsError}
            setTabChange={setTabChange}
            bottom={modelFormData.bottom}
          />
        );
      }
    }
  };

  return (
    <div>
      {renderSteps()}

      <div
        style={{
          mt: 4,
          display: "flex",
          marginTop:  isXs ? "10px" : "50px",
          justifyContent: "space-evenly",
          paddingTop: isXs ? "10px" : "50px",
          paddingBottom: isXs ? "10px" : "30px",
        }}
      >
        {step > 0 && (
          <FormButtonPrevious
            onPrevStep={handleStepChange}
            currentStep={step}
          />
        )}
        {step < maxStep && (
          <FormButtonNext onNextStep={handleStepChange} currentStep={step} />
        )}
      </div>
    </div>
  );
};

export default ConfiguratorForm;