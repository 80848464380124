import React, { useState } from "react";
import { Box, Typography, Card, CardActionArea, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ralToHex } from "../../threejs/modelRenderer";
import InfoNotice from "./InfoNotice";

const TextureForm = ({ selectedMaterial, onMaterialChange, textures }) => {
  const [selectedCategory, setSelectedCategory] = useState("brillants");
  const [selectedColor, setSelectedColor] = useState(null);
  const theme = useTheme();
  // Détecter les breakpoints
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const selectedMaterialData = textures.find(
    (material) => material.id === selectedCategory
  );

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedColor(null);
  };

  const handleColorChange = (colorId) => {
    const color = selectedMaterialData.colors.find((c) => c.id === colorId);
    setSelectedColor(color);
    onMaterialChange(colorId);
  };

  return (
    <Box
      sx={{
        paddingRight: "20px",
        paddingLeft: "20px",
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        align="center"
        sx={{ fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem", lg: "2rem" },
        paddingBottom:{xs: "0px", md:"30px"}, 
      }}
      >
        Choisir mon coloris
      </Typography>

      {selectedColor && selectedColor.showWarningMessage && (
        <InfoNotice
          margin="0 0 20px"
          content="Coloris déconseillé en cas de forte exposition au soleil"
        />
      )}

      {/* Boutons des catégories de matériaux */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap", // Permet aux boutons de passer à la ligne si nécessaire
          gap: "10px",
          mb: 3,
        }}
      >
        {textures.map((material) => (
          <Button
            key={material.id}
            onClick={() => handleCategoryChange(material.id)}
            sx={{
              padding: isXs ? "5px 10px" : "10px 50px", // Réduire le padding en mode mobile
              minWidth: 0, // Permettre aux boutons de se réduire en largeur
              height: "40px",
              textTransform: "none",
              backgroundColor:
                selectedCategory === material.id ? "#007ac3" : "white",
              color: selectedCategory === material.id ? "white" : "black",
              border: "1px solid #007ac3",
              borderRadius: "50px",
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": {
                backgroundColor:
                  selectedCategory === material.id ? "#005a8d" : "#007ac3",
                color: "white",
              },
              flex: "1 1 auto", // Permettre aux boutons de s'étirer ou de se contracter
              fontSize: isXs ? "0.8rem" : "1rem", // Réduire la taille de la police en mode mobile
            }}
          >
            {material.name}
          </Button>
        ))}
      </Box>

      {/* Affichage des textures/couleurs pour la catégorie sélectionnée */}
      {selectedMaterialData && (
        <Box
          sx={{
            display: "flex",
            flexWrap: isXs ? "nowrap" : "wrap",
            overflowX: isXs ? "auto" : "visible",
            justifyContent: isXs ? "normal" : "center",
            gap: 2,
          }}
        >
          {selectedMaterialData.colors.map((color) => color.active ? (
            <CardActionArea
              key={color.id}
              onClick={() => handleColorChange(color.id)}
              sx={{
                borderRadius: 2,
                border:
                  selectedMaterial === color.id
                    ? "3px solid #1976d2"
                    : "1px solid #e0e0e0",
                transition: "box-shadow 0.3s ease-in-out, transform 0.2s",
                width: isXs ? "80px" : "130px",
                height: isXs ? "80px" : "130px",
                flex: "0 0 auto",
                position: "relative",
                marginBottom: 8,
              }}
            >

          <Card
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
              overflow: "hidden",
              backgroundImage: 
                color.colorType === "TEXTURE"
                  ? `url(${color.thumbnailUrl})` // Utiliser l'image comme arrière-plan
                  : "none", // Pas d'image si ce n'est pas TEXTURE
              backgroundColor: 
                color.colorType === "HEX"
                  ? `#${color.color.replace(/^#/, "")}` // Ajouter '#' si nécessaire pour HEX
                  : color.ralColorId
                  ? ralToHex(color.ralColorId, " Card") // Sinon, utiliser ralToHex
                  : "transparent", // Valeur par défaut
              backgroundSize: 
                color.colorType === "TEXTURE"
                  ? "cover" // Ajuster l'image à la taille du bouton
                  : "initial", // Réinitialiser si pas d'image
              backgroundPosition: 
                color.colorType === "TEXTURE"
                  ? "center" // Centrer l'image
                  : "initial", // Réinitialiser si pas d'image
            }}
          ></Card>


                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
              {/* Affichage du nom et du code RAL */}
              {selectedMaterial === color.id && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    // Optionally, add text shadow for better visibility
                  }}
                >
                    {!selectedColor && (
                      <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 900,
                        textTransform: "uppercase",
                        textAlign: "center",
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: color.whiteText ? "white" : "black", // Changer la couleur du texte selon `textWhite`
                      }}
                    >
                      {color.name}
                    </Typography>
                  )}
                  {selectedColor && selectedColor.colorType === "RAL" && (
                      <Typography
                      variant="caption"
                      sx={{
                        fontWeight: 900,
                        textTransform: "uppercase",
                        textAlign: "center",
                        fontStyle: "italic",
                        fontSize: "13px",
                        color: color.whiteText ? "white" : "black", // Changer la couleur du texte selon `textWhite`
                      }}
                    >
                      {color.name} (RAL{color.ralColorId})
                    </Typography>
                  )}
                  {selectedColor && selectedColor.colorType != "RAL" && (<Typography
                    variant="caption"
                    sx={{
                      fontWeight: 900,
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: color.whiteText ? "white" : "black", // Changer la couleur du texte selon `textWhite`
                    }}
                  >
                    {color.name}
                  </Typography>
                  )}
                </Box>
              )}
                                  {selectedMaterial !== color.id && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "'Fira Sans', Helvetica, Arial, Lucida, sans-serif",
                  textTransform: 'uppercase',
                  fontSize: "13px",
                  mt: 1,
                }}
              >
                {color.name}
              </Typography>
            )}
            </Box>
            </CardActionArea>
            
          ) : null)}
        </Box>
      )}
    </Box>
  );
};

export default TextureForm;
