import React from "react";
import { Box, Typography } from "@mui/material";

const FeaturesGrid = () => {
  const featuresData = [
    {
      image: "../images/Infos/livraison-1.png",
      title: "Livraison rapide en deux semaines",
      width: 77,
      height: 50,
    },
    {
      image: "../images/Infos/paiement.png",
      title: "Paiement Sécurisé",
      width: 70,
      height: 50,
    },
    {
      image: "../images/Infos/france-coq.png",
      title: "Fabriqué en France",
      width: 50,
      height: 50,
    },
    {
      image: "../images/Infos/suivi.png",
      title: "Suivi de commande et de livraison",
      width: 37,
      height: 50,
    },
  ];

  const Feature = ({ image, title, width, height }) => {
    return (
      <Box
        sx={{
          textAlign: "center",
          flex: "1 1 20%",
          minWidth: { xs: "50px", sm: "80px", md: "100px" },
          margin: "0 10px",
        }}
      >
        <Box
          component="img"
          src={image}
          alt={title}
          sx={{
            width: { xs: `${width * 0.7}px`, sm: `${width * 0.85}px`, md: `${width}px` },
            height: { xs: `${height * 0.7}px`, sm: `${height * 0.85}px`, md: `${height}px` },
          }}
        />
        <Typography
          sx={{
            fontSize: { xs: "0.8em", sm: "0.9em", md: "1em" },
            marginBottom: "5px",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "wrap", // Autorise les éléments à passer à la ligne si nécessaire
        backgroundColor: "#f4f4f4",
        padding: { xs: "20px 10px", sm: "30px 40px", md: "40px 180px" },
      }}
    >
      {featuresData.map((feature, index) => (
        <Feature
          key={index}
          image={feature.image}
          title={feature.title}
          width={feature.width}
          height={feature.height}
        />
      ))}
    </Box>
  );
};

export default FeaturesGrid;
