import {
    Vector2,
    Mesh,
    PerspectiveCamera,
    WebGLRenderer,
    Scene,
    MeshStandardMaterial,
    Color,
    BoxGeometry,
    DirectionalLight,
    PCFSoftShadowMap,
    Vector3,
    TextureLoader,
    MeshPhysicalMaterial,
    FrontSide,
    BackSide
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { fetchMaterialInfo } from "./modelRenderer";

//#region Constants

const cameraOriginalPosition = new Vector3(1, 0.375, -0.25);
const cubeMaterialSettings = {
    color: 0xbbb0aa,
    emissive: 0x101010,
    emissiveIntensity: 1,
};

const acToCover = {
    x: 10.6 + 8,
    y: 8.5,
    z: 6.0,
};
const SHADOWSIZE = 2024
const SHADOW_CAMERA_SIZE = 120
const SHADOW_FAR = 500
const SHADOW_NEAR = 0.5

let scene;

let currentObjects = {};
let placeholderCube = null;
let isScenePaused = false
//#endregion Constants

//#region LifeCycle

/**
 * Cette méthode sert à créer une nouvelle scène 3D
 * @param {HTMLElement} content Le conteneur de la scène
 * @returns {Object} La scène 3D
 */
const CreateThreeScene = (content) => {
    const renderer = createRenderer(content);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    scene = new Scene();
    const camera = createCamera();
    const orbitControls = createOrbitControls(camera, renderer);

    const setSize = (width, height) => {
        renderer.setSize(width, height);
        setCameraSize(camera, width, height);
    };

    const start = (onUpdate) => {
        startRenderLoop(content, renderer, camera, orbitControls, onUpdate);
    };

    const pauseScene = (on) => {
        orbitControls.enableRotate = on;
        orbitControls.enablePan = on; 
        orbitControls.autoRotate = on;
        if (on === false && previousOnState === true) {
            storeOriginalMaterials();
        }
        applyPauseEffect(on);
    };
    
    const updateMainMaterial = updateMaterial();
    
    setupScene();
    createLights()
    
    const updatePlaceholderObject = updatePlaceholder(camera, orbitControls);

    return {
        setSize,
        start,
        updateMainMaterial,
        updatePlaceholderObject,
        pauseScene,
    };
};

/**
 * Cette méthode sert à créer les lumières de la scène
 * @returns {void}
 */
const createLights = () => {
    createLight(20, 50, 0, 2, "Front");
    createLight(80, 10, 80, 2, "Front Left");
    createLight(80, 80, -80, 2, "Top Right");
    createLight(-80, 0, 0, 2, 'Behind');
    createLight(-80, 80, 80, 2, 'Behind Top Left');
}

/**
 * Cette méthode sert à démarrer la boucle de rendu de la scène
 * @param {HTMLElement} content Le conteneur de la scène
 * @param {WebGLRenderer} renderer Le renderer de la scène
 * @param {PerspectiveCamera} camera La caméra de la scène
 * @param {OrbitControls} orbitControls Les contrôles de la caméra
 * @param {Function} onUpdate La fonction à appeler à chaque frame
 * @returns {void}
 * @see https://threejs.org/docs/#api/en/renderers/WebGLRenderer
 * @see https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
 * @see https://threejs.org/docs/#examples/en/controls/OrbitControls
 */
const startRenderLoop = (content, renderer, camera, orbitControls) => {
    const render = () => {
        if (document.body.contains(content)) {
            orbitControls.update();
            renderer.render(scene, camera);
            requestAnimationFrame(render);
        }
    };

    render();
};


/**
 * Cette méthode sert à setup la scène et est appelée au démarrage de l'application seulement
 * @returns {void}
 */
const setupScene = () => {
    scene.background = new Color('white');
};

/**
 * Cette méthode sert à configurer un objet pour qu'il projette des ombres et qu'il en reçoive
 * @param {Object3D} object L'objet à configurer
 * @returns {void}
 * @see https://threejs.org/docs/#api/en/core/Object3D
 */
const configureObject = (object) => {
    if (object.type === "Group") {
        object.children.forEach((object) =>
            configureObject(object)
    );
} else {
    object.castShadow = true;
    object.receiveShadow = true;
}
};

/**
 * Cette méthode sert à ajouter un objet à la scène
 * @param {Object3D} object L'objet à ajouter à la scène
 * @param {string} face Le nom de la face à laquelle l'objet est associé
 * @returns {void}
 */
const addObjectToScene = (object, face) => {
    removeObjectFromScene(face);
    scene.add(object);
    configureObject(object);
    currentObjects[face] = object;
};

/**
 * Cette méthode sert à retirer un objet de la scène
 * @param {string} faceName Le nom de la face à laquelle l'objet est associé
 * @returns {void}
 */
const removeObjectFromScene = (faceName) => {
    if(currentObjects[faceName]){
        scene.remove(currentObjects[faceName]);
        delete currentObjects[faceName];
    }
};

const updatePlaceholder = (camera, orbitControls) => {
    return (object, size) => {
        if(isScenePaused) return;
        if(placeholderCube){
            scene.remove(placeholderCube)
        }

        const parsedSize = {
            x: size.x * 0.1,
            y: size.y * 0.1,
            z: size.z * 0.1,
        }
        const geometry = new BoxGeometry(
            parsedSize.x - acToCover.x,
            parsedSize.z - acToCover.z,
            parsedSize.y - acToCover.y,
        );
        const material = new MeshStandardMaterial(cubeMaterialSettings);
        const cube = new Mesh(geometry, material)
        cube.name = "placeholder"
        cube.receiveShadow = true;
        cube.castShadow = true;
        cube.position.x = cube.position.x + 1.7
        scene.add(cube)

        placeholderCube = cube;
        resetCamera(camera, orbitControls, size, parsedSize);
    }
}

/**
 * Cette méthode sert à mettre à jour le matériau de la scène sans recharger les faces de l'objet
 * @returns {Function} La fonction qui met à jour le matériau de la scène
 * @see https://threejs.org/docs/#api/en/materials/MeshStandardMaterial
 */
const updateMaterial = () => {
    return async (materialId) => {
  
      try {
        const materialInfos = await fetchMaterialInfo(materialId);
        const computedMaterialInfos = {
            color: new Color(materialInfos.color),
            reflectivity : materialInfos.reflectivity,
            roughness: materialInfos.roughness,
            metalness: materialInfos.metalness,
            clearcoat: materialInfos.clearcoat,
            clearcoatRoughness: materialInfos.clearcoatRoughness
        };
        const newMaterial = new MeshPhysicalMaterial(computedMaterialInfos);  
        if ("colorMapUrl" in materialInfos && materialInfos.colorMapUrl !== undefined) {
            const textureLoader = new TextureLoader();
  
          textureLoader.load(
            materialInfos.colorMapUrl,
            (texture) => {
  
              newMaterial.map = texture;
              newMaterial.map.needsUpdate = true;
              if (newMaterial.map && newMaterial.map.image) {
              } else {
                console.error("Texture failed to apply.");
              }
              if(isScenePaused) return;

              scene.traverse((object) => {
                if (object.isMesh && object.name !== "placeholder" && !object.isHelper) {
                  object.material = newMaterial.clone();
                  object.material.needsUpdate = true;
                }
              });
            },
            undefined,
            (error) => {
              console.error("Error loading texture:", error);
            }
          );
        } else {
            if(isScenePaused) return;

          scene.traverse((object) => {
            if (object.isMesh && object.name !== "placeholder" && !object.isHelper) {
              object.material = newMaterial.clone();
              object.material.needsUpdate = true;
            }
          });
        }
      } catch (error) {
        console.error("Error updating material:", error);
      }
    };
  };
  
  

/**
 * Cette méthode sert à instantier une lumière directionnelle
 * @param {number} x La position x de la lumière
 * @param {number} y La position y de la lumière
 * @param {number} z La position z de la lumière
 * @param {number} i L'intensité de la lumière
 * @param {string} name Le nom de la lumière
 * @returns {void}
 * @see https://threejs.org/docs/#api/en/lights/DirectionalLight
 */
const createLight = (x, y, z, i, name) => {
    const light = new DirectionalLight(0xffffff, i);
    light.position.set(x, y, z);
    light.castShadow = true;
    light.shadow.mapSize = new Vector2(SHADOWSIZE, SHADOWSIZE);
    light.shadow.camera.near = SHADOW_NEAR;
    light.shadow.camera.far = SHADOW_FAR;
    light.shadow.camera.left = SHADOW_CAMERA_SIZE;
    light.shadow.camera.bottom = SHADOW_CAMERA_SIZE;
    light.shadow.camera.right = -SHADOW_CAMERA_SIZE;
    light.shadow.camera.top = -SHADOW_CAMERA_SIZE;
    light.shadow.bias = -0.005;  // Ajuster pour éviter les artefacts d'ombre

    scene.add(light);
  };
//#endregion LifeCycle

//#region Controls

/**
 * Cette méthode sert à réinitialiser la caméra
 * @param {PerspectiveCamera} camera La caméra de la scène
 * @param {OrbitControls} orbitControls Les contrôles de la caméra
 * @param {Object} size La taille de l'objet
 */
const resetCamera = (camera, orbitControls, size) => {    
    const newDistanceVert = getNecessaryDistance(size.z*0.1, getVerticalFovRad(camera), size.x * 0.5);
    const newDistanceHoriz = getNecessaryDistance(size.y*0.1, getHorizontalFovRad(camera), size.x * 0.5);
    const newDistance = Math.max(newDistanceVert, newDistanceHoriz) * 0.55;

    orbitControls.minDistance = newDistance/3;
    orbitControls.maxDistance = newDistance*2;

    const currentDistance = camera.position.length();
    const cameraDistanceRatio = newDistance / currentDistance;
    camera.position.x *= cameraDistanceRatio;
    camera.position.y *= cameraDistanceRatio;
    camera.position.z *= cameraDistanceRatio;
    orbitControls.update();
};

/**
 * Cette méthode sert à créer les contrôles de la caméra
 * @param {PerspectiveCamera} camera La caméra de la scène
 * @param {WebGLRenderer} renderer Le renderer de la scène
 * @returns {OrbitControls} Les contrôles de la caméra
 */
const createOrbitControls = (camera, renderer) => {
    const orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.enablePan = false;
    orbitControls.enableDamping = true;
    orbitControls.enableZoom = false;
    orbitControls.dampingFactor = 0.1;
    orbitControls.autoRotate = true;
    orbitControls.autoRotateSpeed = 1;
    orbitControls.target.set(0, 0, 0);
    const minAngle = -Math.PI / 3;
    const maxAngle = Math.PI / 3;
    let autoRotateDirection = 1;

    const calculateRealAngle = () => {
        const position = camera.position.clone();
        position.sub(orbitControls.target);
        return Math.atan2(position.z, position.x);
    };

    const updateAutoRotate = () => {
        const realAngle = calculateRealAngle();
        if (realAngle >= maxAngle) {
            autoRotateDirection = -1;
        } else if (realAngle <= minAngle) {
            autoRotateDirection = 1;
        }
        orbitControls.autoRotateSpeed = Math.abs(orbitControls.autoRotateSpeed) * autoRotateDirection;
    };

    const originalUpdate = orbitControls.update.bind(orbitControls);
    orbitControls.update = () => {
        if (orbitControls.autoRotate) {
            updateAutoRotate();
        }
        originalUpdate();
    };

    return orbitControls;
};

//#endregion Controls

//#region Helpers

const createRenderer = (content) => {
    const renderer = new WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth * 2, window.innerHeight * 2);
    renderer.setPixelRatio(2);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap; 
    
    content.appendChild(renderer.domElement);
    return renderer;
};

const createCamera = () => {
    const camera = new PerspectiveCamera(60, 1, 1, 5000);
    camera.position.x = cameraOriginalPosition.x;
    camera.position.y = cameraOriginalPosition.y;
    camera.position.z = cameraOriginalPosition.z;
    return camera;
};

const setCameraSize = (camera, width, height) => {
    if (height > 0) {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
    }
};

const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
};

const getVerticalFovRad = (camera) => {
    return degreesToRadians(camera.fov);
};

const getHorizontalFovRad = (camera) => {
    const aspect = camera.aspect;
    const fov = getVerticalFovRad(camera);
    const dist = 1 / Math.tan(fov * 0.5);
    const halfFovH = Math.atan(aspect / dist);
    return halfFovH * 2;
};

const getNecessaryDistance = (objectDimensionSize, dimensionFov, objectDepthFrom0) => {
    const halfSize = 0.5 * objectDimensionSize;
    const halfFov = dimensionFov * 0.5;
    const baseNecessaryDistance = halfSize / Math.tan(halfFov);
    return baseNecessaryDistance + objectDepthFrom0;
};

let previousOnState = true;
const grayMaterialWithGlow = new MeshPhysicalMaterial({
    color: 0x808080,   // Gris
    emissive: 0x505050,  // Emissif pour un léger halo
    emissiveIntensity: 1,
    roughness: 0.8,
    metalness: 0.2,
    transparent: false,  // Permet de jouer avec l'opacité
    opacity: 0.9,       // Réduit l'opacité pour simuler un état non interactif
    clearcoat: 0,        // Supprimer le clearcoat pour un aspect plus matte
    clearcoatRoughness: 0.8, // Réduire la brillance
});

let originalMaterials = {};

const storeOriginalMaterials = () => {
    scene.traverse((object) => {
        if (object.isMesh && object.name !== "placeholder" && !object.isHelper) {
            originalMaterials[object.name] = object.material;
        }
    });
};

const applyPauseEffect = (on) => {
    scene.traverse((object) => {
        if (object.isMesh && !object.isHelper) {
            if (!on) {
                 object.material = grayMaterialWithGlow;
                 object.material.side = FrontSide;
            } else {
                object.material = originalMaterials[object.name] || new MeshStandardMaterial(cubeMaterialSettings);
            }
            object.material.needsUpdate = true;
        }
    });
};


//#endregion Helpers

export {addObjectToScene, removeObjectFromScene};
export default CreateThreeScene;