import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardActionArea,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const TypeForm = ({
  selectedType,
  onTypeChange,
  bottomFace,
  onBottomFaceChange,
}) => {
  const installationTypes = [
    {
      id: "facade-haut",
      label: "Sur façade en hauteur",
      imgSrc: "/types/facade-haut.png",
      info: "Mon unité repose sur une console ou des équerres fixée(s) au mur, en hauteur",
    },
    {
      id: "facade-sol",
      label: "Sur façade proche du sol",
      imgSrc: "/types/facade-sol.png",
      info: "Mon unité repose sur une console ou des équerres fixée(s) au mur, proche du sol",
    },
    {
      id: "sol",
      label: "Au Sol",
      imgSrc: "/types/sol.png",
      info: "Mon groupe extérieur repose sur des supports anti-vibration (silentbloc), au pied d'un mur.",
    },
  ];

  const [selectedInfo, setSelectedInfo] = useState(null);
  const [selectedInfoFaceDessous, setSelectedInfoFaceDessous] = useState(false);
  const popupRef = useRef(null);

  const handleInfoClick = (id) => {
    setSelectedInfo(selectedInfo === id ? null : id);
  };

  const handleInfoClickFaceDessous = () => {
    setSelectedInfoFaceDessous(true);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSelectedInfo(null);
      setSelectedInfoFaceDessous(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ paddingRight: "20px", paddingLeft: "20px" }}>
      <Typography
        variant="h3"
        gutterBottom
        align="center"
        sx={{ fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem", lg: "2rem" } }}
      >
        Préciser mon type d'installation
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {installationTypes.map((type) => (
          <Grid
            key={type.id}
            item
            sx={{
              width: { xs: 200, sm: 220, md: 250 },
              position: "relative",
            }}
          >
            <CardActionArea
              onClick={() => onTypeChange(type.id)}
              sx={{
                borderRadius: 2,
              }}
            >
              {/* Image */}
              <Card
                sx={{
                  borderRadius: 2,
                  overflow: "hidden",
                  height: { xs: 180, sm: 200, md: 220 },
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border:
                    selectedType === type.id ? "2px solid #1976d2" : "none",
                }}
              >
                <Box
                  component="img"
                  src={type.imgSrc}
                  alt={type.label}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Card>

              {/* Texte descriptif avec icône d'information */}
              <Box
                sx={{
                  textAlign: "center",
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{type.label}</Typography>
                <InfoIcon
                  sx={{
                    fontSize: "18px",
                    color: "#007ac3",

                    ml: 1,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleInfoClick(type.id);
                  }}
                />
              </Box>
            </CardActionArea>

            {selectedInfo === type.id && (
              <Box
                ref={popupRef}
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  mt: 1,
                  width: "200px",
                  p: 2,
                  zIndex: 1,
                  fontWeight: "300",
                  padding: "20px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
                  borderRadius: "5px",
                  fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
                  fontSize: "13px",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  {type.info}
                </Typography>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>

      {/* Afficher la checkbox uniquement si "Sur façade en hauteur" est sélectionné */}
      {selectedType === "facade-haut" && (
        <Box sx={{ mt: 3, textAlign: "center" }}>
          {/* Conteneur pour aligner les éléments */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Aligne verticalement
              justifyContent: "center", // Centre horizontalement
              gap: 1, // Espacement entre les éléments
            }}
          >
            {/* Checkbox et Label */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={bottomFace}
                onChange={(e) => onBottomFaceChange(e.target.checked)}
                sx={{ p: 0, mr: 1 }} // Réduction de l'espace autour du Checkbox
              />
              <Typography
                variant="body1"
                sx={{
                  color: "#000",
                }}
              >
                J'ajoute la face du dessous
              </Typography>
            </Box>

            {/* Icône alignée */}
            <InfoIcon
              sx={{
                fontSize: "18px",
                color: "#007ac3",

                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleInfoClickFaceDessous();
              }}
            />
          </Box>

          {/* Info Popup */}
          {selectedInfoFaceDessous === true && (
            <Box
              ref={popupRef}
              sx={{
                position: "relative",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                mt: 1,
                width: "200px",
                zIndex: 1,
                fontWeight: "300",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                boxShadow: "0 0 35px rgb(0 0 0 / 19%)",
                borderRadius: "5px",
                fontFamily: "'Fira Sans',Helvetica,Arial,Lucida,sans-serif",
                fontSize: "13px",
              }}
            >
              <Typography variant="body2" color="textSecondary" sx={{}}>
                Je peux voir <u>sous</u> mon groupe extérieur, je souhaite le
                cacher aussi.
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TypeForm;
