import React from "react";
import { Box, Typography, CardActionArea } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PatternForm = ({ selectedPattern, onPatternChange, patterns }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Box       sx={{
      paddingRight: "20px",
      paddingLeft: "20px",
    }}>
      <Typography
        variant="h3"
        gutterBottom
        align="center"
        sx={{ 
          fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem", lg: "2rem" },
          paddingBottom:{xs: "0px", md:"30px"}, 
        }}
        
      >
        Choisir mon motif
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: isXs ? "nowrap" : "wrap",
          overflowX: isXs ? "auto" : "visible",
          justifyContent: isXs ? "normal" : "center",
          gap: isXs ? 2 : 3,
        }}
      >
        {patterns.map((pattern) => (
          <Box key={pattern.id} sx={{ textAlign: "center" }}>
            <CardActionArea
              onClick={() =>
                onPatternChange({ target: { value: pattern.name } })
              }
              sx={{
                width: isXs ? "80px" : "130px",
                height: isXs ? "80px" : "130px",
                flex: "0 0 auto",
                borderRadius: 2,
                overflow: "hidden",
                position: "relative",
                border:
                  selectedPattern === pattern.name
                    ? "2px solid #007ac3"
                    : "2px solid transparent",
                transition: "border-color 0.3s ease-in-out",
                "&:hover img": {
                  opacity: 1,
                },
                "&:hover": {
                  borderColor: "#007ac3",
                },
              }}
            >
              <Box
                component="img"
                src={pattern.url}
                alt={pattern.name}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 0.25,
                  transition: "opacity 0.3s ease-in-out",
                }}
              />
              {selectedPattern === pattern.name && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    bgcolor: "rgba(25, 118, 210, 0.7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#fff",
                      fontWeight: 900,
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontStyle: "italic",
                      fontSize: "13px",
                    }}
                  >
                    {pattern.name}
                  </Typography>
                </Box>
              )}
            </CardActionArea>
            {selectedPattern !== pattern.name && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  fontFamily: "'Fira Sans', Helvetica, Arial, Lucida, sans-serif",
                  textTransform: 'uppercase',
                  mt: 1,
                }}
              >
                {pattern.name}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PatternForm;
