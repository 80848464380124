import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

// Styled button
const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 50,
  backgroundColor: "#f0c300",
  color: "grey",
  borderRadius: "50px",
  border: "1px solid #e5e1e1",
  fontWeight: "bold",
  textTransform: "none",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px",
  "&:hover": {
    backgroundColor: "#007ac2",
    color: "white",
    "& .add-to-cart-text": {
      color: "white",  // Texte "Ajouter au panier" devient blanc au survol
    },
  },
  "&:disabled": {
    backgroundColor: "#E0E0E0",
    color: "#A0A0A0",
    opacity: "0.5",
  },

  [theme.breakpoints.down("md")]: {
    transform: "translateY(-15px)",
  },
}));

const AddToCartButton = ({ price = 0, isDisabled, quotationDatas, handleAddToCart, hidePrice}) => {
  const [askForQuotation, setAskForQuotation] = useState(false);
  const ttcPrice = price;
  const httPrice = ttcPrice / (1 + (20 / 100))
  useEffect(() => {
    setAskForQuotation(quotationDatas.isNeeded);
  }, [quotationDatas.isNeeded]);

 

  return (
    <StyledButton
      disabled={isDisabled}
      onClick={handleAddToCart}
      sx={{
        opacity: hidePrice ? 0 : 1,           // Rendre le bouton invisible
        pointerEvents: hidePrice ? "none" : "auto", // Désactiver les interactions
        visibility: hidePrice ? "hidden" : "visible", // Assurer qu'il ne soit pas interactif
        transition: "opacity 0.3s ease",     // Ajouter une transition pour une disparition fluide
      }}
    >
      <Box
        sx={{
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          opacity: "1",
          border: "1px solid #e5e1e1",
          borderRadius: "50px",
          minWidth: "80px",
          padding: "5px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            flexGrow: 5,
            color: "#007ac2",
            fontWeight: "bold",
            fontSize: { xs: '0.8rem', sm: '1rem', md: '0.7rem', lg: '0.85rem'},
          }}
          >
          {ttcPrice > 0 && (
              <>
              {ttcPrice}€ TTC      
              </>
          )}
          {ttcPrice <= 0 && (
              <>
              € TTC
              </>
          )}

        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            flexGrow: 3,
            color: "#000",
            fontSize: { xs: '0.6rem', sm: '0.8rem', md: '0.5rem', lg: '0.7rem'},
          }}
        >
          {httPrice > 0 && (
            <>{httPrice.toFixed(2)}€ HT</>
          )}

          {httPrice <= 0 && (
            <>  € HT</>
          )}
        </Typography>
      </Box>
      <Typography
       className="add-to-cart-text"
        sx={{
          textAlign: "center",
          flexGrow: 1,
          fontSize: { xs: '1rem', sm: '1rem', md: '0.70rem', lg: '1rem'},
          fontWeight: "bold",
          color: "black",
          whiteSpace: "nowrap", // Prevent text wrapping
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: "90px",
        }}
      >
        Ajouter au panier
      </Typography>
    </StyledButton>
  );
};

export default AddToCartButton;
