import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepButton, Box } from "@mui/material";
import AddToCartButton from "./AddToCartButton"; // Import du composant AddToCartButton

// Custom connector for the Stepper
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    backgroundColor: "#ccc",

  },
}));

// Step Icon Styling
const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
  backgroundColor: "#f1f1f1",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  justifyContent: "center", // Centre horizontalement
  alignItems: "center", // Centre verticalement
  borderRadius: "50%",
  border: "2px solid #ccc",
  cursor: "pointer",
  transition: "border-color 0.3s ease",
  paddingRight: 0,
  margin: "0 auto", // Centre dans son conteneur parent
  ...(ownerState.active && {
    borderColor: "#007ac3",
  }),
  "&:hover": {
    borderColor: "#007ac3",
  },
}));


function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  // Définir les icônes pour les étapes avec condition pour l'étape 2 en mode mobile
  const icons = {
    1: "/icons/motifs.png",
    2: isXs ? "/icons/climatisation-2.png" : "/icons/texture.png", // TypeForm icon for mobile
    3: isXs ? "/icons/choix.png" : "/icons/climatisation-2.png",
    4: "/icons/choix.png",
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
<img
  src={icons[String(icon)]}
  alt={`Étape ${icon}`}
  style={{
    display: "block", // Supprime les espaces indésirables
    margin: "0 auto", // Centre horizontalement
    width: "24px",
    height: "24px",
  }}
/>

    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const ConfiguratorMenu = ({ currentStep, onStepChange, quotationDatas, price, isButtonCartDisabled, modelFormData, materialFormData, handleAddToCart, hidePrice }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  // Adapter les étapes en fonction du breakpoint (mobile ou non)
  const steps = isXs
    ? ["Motif & Texture", "Type", "Dimensions"]
    : ["Motif", "Texture", "Type", "Dimensions"];

    const [topValue, setTopValue] = useState("9vh");

    useEffect(() => {
      const handleResize = () => {
        const viewportHeight = window.innerHeight;
        if (viewportHeight <= 1000) {
          setTopValue("14vh"); // Ajustement pour petits écrans
        } else if (viewportHeight > 1200) {
          setTopValue("9vh"); // Ajustement pour grands écrans
        } else if (viewportHeight > 1700) {
          setTopValue("6.5vh"); // Ajustement pour grands écrans
        } else if (viewportHeight > 2100) {
          setTopValue("8vh"); // Ajustement pour grands écrans
        } else {
          setTopValue("16.5vh");
        }
      };
  
      handleResize(); // Appel initial
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  const handleStep = (newStep) => {
    onStepChange(newStep);
  };

   return (
    <>
      <Box
        sx={{
          left: isXs ? "50vw" : "45vw",
          transform: isXs ? "translate(-50%, 0%)" : "translate(-30%, 0%)",
          display: "flex",
          justifyContent: isXs ? "center" : "flex-end",
          alignItems: "center",
          width: "50vw",
          position: "sticky",
          top: topValue,
          zIndex: 999,
          padding: "10px 0",
        }}
      >
        {/* Section du Stepper */}
        <Stack
          sx={{
            flexGrow: isXs ? "unset" : "5", // Largeur sur écrans moyens et grands
            display: "flex",
            justifyContent: "center",
            width: isXs ? "90%" : "auto", // Largeur plus compacte pour mobile
          }}
          spacing={isXs ? 2 : 4} // Espacement réduit entre étapes sur mobile
        >
          <Stepper
            nonLinear
            activeStep={currentStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}
              sx={{
                paddingLeft: isXs ? 0 : "8px", // Supprime le padding gauche
                paddingRight: isXs ? 0 : "8px",  // Supprime le padding droit
              }}>
                <StepButton color="inherit" 
                onClick={() => handleStep(index)}
                disableRipple
                sx={{
                  display: "flex", // Transforme en conteneur flex
                  flexDirection: "column", // Aligne le contenu verticalement
                  justifyContent: "center", // Centre verticalement
                  alignItems: "center", // Centre horizontalement
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}>
                  <StepLabel StepIconComponent={(props) => (
                    <ColorlibStepIcon {...props} icon={index + 1} />
                  )}
                  sx={{
                    "& .MuiStepLabel-iconContainer": {
                      paddingRight: 0, // Supprime le padding
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  ></StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Stack>

        {/* Afficher le bouton pour grand écran */}
        <Box
          sx={{
            flexGrow: "2",
            display: { xs: "none", md: "flex" },
          }}
        >
          <AddToCartButton price={price} quotationDatas={quotationDatas} isDisabled={isButtonCartDisabled} modelFormData={modelFormData} materialFormData={materialFormData} handleAddToCart={handleAddToCart} hidePrice={hidePrice} />
        </Box>
      </Box>

      {/* Afficher le bouton en bas pour petit écran */}
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 20,
        }}
      >
        <AddToCartButton price={price} quotationDatas={quotationDatas} isDisabled={isButtonCartDisabled}  modelFormData={modelFormData} materialFormData={materialFormData} handleAddToCart={handleAddToCart} hidePrice={hidePrice}/>
      </Box>
    </>
  );
};

export default ConfiguratorMenu;