import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "./Components/Layout/ResponsiveAppBar";
import Bandeau from "./Components/ConfigurationsSteps/Bandeau";
import Footer from "./Components/Layout/Footer";
import InfoBar from "./Components/ConfigurationsSteps/InfoBar";
import DiversBar from "./Components/ConfigurationsSteps/DiversBar";
import Configurator from "./pages/Configurator";
import BadgeWidget from "./Components/ConfigurationsSteps/BadgeWidget";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import "./css/App.css";

const theme = responsiveFontSizes(
  createTheme({
    cssVariables: true,
    palette: {
      mode: "light",
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
      h3: {
        color: "#007ac3",
        fontSize: "22px",
        fontWeight: "700",
        paddingBottom: "30px",
      },
    },
  })
);

const getWooCommerceCartItems = () => {
  const cookies = document.cookie.split('; ');
  const cartCookie = cookies.find((cookie) =>
    cookie.startsWith('woocommerce_items_in_cart=')
  );

  if (cartCookie) {
    const itemsInCart = parseInt(cartCookie.split('=')[1], 10);
    console.log("Nombre d'articles dans le panier :", itemsInCart);
    return itemsInCart;
  }

  console.log('Aucun article dans le panier.');
  return 0;
};

const App = () => {
  const [appBarHeight, setAppBarHeight] = useState(0);
  const [itemsInCart, setItemsInCart] = useState(0);

  useEffect(() => {
    const items = getWooCommerceCartItems();
    setItemsInCart(items);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar setAppBarHeight={setAppBarHeight} />
      <Bandeau appBarHeight={appBarHeight} />
      <Configurator />
      <BadgeWidget/>
      <InfoBar />
      <DiversBar />
      <Footer />  
    </ThemeProvider>
    
  );
};

export default App;
