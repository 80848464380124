import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

export default function FreeShippingBanner({ appBarHeight }) {
  const texts = [
    "Livraison offerte sur toutes les commandes !",
    "Un proche souhaite aussi s'équiper ? Profitez d'une remise de 24€ par cache dès le deuxième."
  ];

  const [currentText, setCurrentText] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentText((prevText) => (prevText + 1) % texts.length);
        setFade(true);
      }, 1000); // Durée du fade-out (doit correspondre à la durée de transition CSS)
    }, 5000); // Change le texte toutes les 5 secondes

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <Box
      sx={{
        backgroundColor: "#5cb1e2",
        padding: "10px 0",
        marginTop: `${appBarHeight}px`,
        textAlign: "center",
        height: { xs: "40px", sm: "45px" }, // Hauteur de 40px pour mobile et 45px pour PC
        display: "flex",
        alignItems: "center", // Centre verticalement
        justifyContent: "center", // Centre horizontalement
        minHeight: "40px", // Ajouté pour éviter que le conteneur se redimensionne
        overflow: "hidden", // Empêche le texte de déborder
      }}
    >
      <Typography
        component="h3"
        sx={{
          color: "white",
          fontWeight: "bold",
          whiteSpace: "normal", // Permet au texte de passer à la ligne si nécessaire
          wordWrap: "break-word", // Permet au texte de se découper pour éviter le débordement
          fontSize: {
            xs: "9px", // Taille de 9px pour les petits écrans
            sm: "14px", // Taille plus grande pour les écrans plus larges
            md: "16px", // Taille encore plus grande pour les écrans moyens
            lg: "18px", // Taille sur grands écrans
          },
          opacity: fade ? 1 : 0,
          transition: "opacity 1s ease-in-out", // Transition uniquement pour l'opacité
          lineHeight: 1.2, // Ajuste l'espacement entre les lignes pour la lisibilité
          textOverflow: "clip", // Pas d'ellipse, le texte reste complet
          overflow: "hidden", // Cache tout débordement
          width: "100vw", // S'assure que le texte prend toute la largeur
          fontFamily: '"Fira Sans", Sans-serif',
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {texts[currentText]}
      </Typography>
    </Box>
  );
}
