import {
  MeshPhysicalMaterial,
  TextureLoader,
  RepeatWrapping,
  Vector2,
  MirroredRepeatWrapping,
  Color
} from "three";
import queryString from "query-string";
import createWebContext from "./webContext.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import colours from "./colours.json";
import { addObjectToScene, removeObjectFromScene } from "./renderer";

let bearerToken = null;

//#region Core

/**
 * @param {any} content TODO
 * @returns {update3DObject} Renvoie la méthode setModel utilisable pour update l'objet 3D en fonction de paramètres
 */
let isScenePaused = false;
const createModelContext = (content) => {
  const { updateObject, updateMaterial, updatePlaceholderObject, pauseScene} = createWebContext(content);
  let lastPromise = null;
  let abortController = null;
  let previousParams = null;
  let faces = null;

  const update3DModel = (params) => {
    const facesToRecalculate = ["left", "right", "front", "top", "bottom"];
  
    // Vérifiez si un paramètre a changé
    const hasParamsChanged = previousParams === null || JSON.stringify(params) !== JSON.stringify(previousParams);
  
    if (!hasParamsChanged) {
      return;
    }
  
    // Si "bottom" est désactivé, retirez-le des faces à recalculer
    if (!params.bottom) {
      removeObjectFromScene("bottom");
      facesToRecalculate.splice(facesToRecalculate.indexOf("bottom"), 1);
    }
  
    // Annulez les requêtes en cours
    if (abortController !== null) {
      abortController.abort();
    }
    abortController = new AbortController();
    const { signal } = abortController;
  
    // Fonction pour mettre à jour l'objet 3D
    return updateObject(async () => {
      let lastComputedObject = null;
  
      const promises = facesToRecalculate.map((face) => {
        return fetchFaceData(
          {
            ...params,
            size: params.size,
            signal,
          },
          face
        )
          .then((fetchedData) => {
            const object = fetchedData.object.scene;
            const material = fetchedData.material;
  
            const computedObject = generateComputedObject({
              object,
              material,
            });
  
            return computedObject;
          })
          .catch((error) => {
            console.error("Failed to fetch face data:", error);
            return null;
          });
      });
  
      const computedObjects = await Promise.all(promises);
        computedObjects.forEach((computedObject, index) => {
        if (computedObject) {
          addObjectToScene(computedObject, facesToRecalculate[index]);
        }
      });
  
      previousParams = params;
      if(!isScenePaused) updatePlaceholderObject(computedObjects[computedObjects.length - 1], params.size);

      return {
        object: computedObjects[computedObjects.length - 1], // Dernier objet calculé
        size: params.size,
      };
    });
  };

  const update3DMaterial = (materialId) => {
    return updateMaterial(materialId);
  };

  const switchPauseScene = (on) => {
    isScenePaused = !on;
    return pauseScene(on)
  }

  return {
    update3DModel,
    update3DMaterial,
    switchPauseScene
  };
};

//#endregion Core

//#region Generation

/**
 * @param {any} materialInfos contient les informations du material à créer
 * Si une normal map est défini alors on l'a télécharge
 * @returns {MeshPhysicalMaterial} Renvoie le material instancié
 */
 const generateComputedMaterial = (materialInfos) => {
  const computedMaterialInfos = {
    color: new Color(materialInfos.color),
    reflectivity : materialInfos.reflectivity,
    roughness: materialInfos.roughness,
    metalness: materialInfos.metalness,
    clearcoat: materialInfos.clearcoat,
    clearcoatRoughness: materialInfos.clearcoatRoughness
};

if ("colorMapUrl" in materialInfos && materialInfos.colorMapUrl !== undefined) {
    computedMaterialInfos.map = fetchTexture(materialInfos.colorMapUrl, materialInfos.textureSize);
}
return new MeshPhysicalMaterial(computedMaterialInfos);
};

/**
 * @param {any} object l'objet 3D sur lequel on doit appliquer le material
 * @param {any} material le material à appliquer sur l'objet 3D
 * @returns {any} Renvoie l'objet avec le material appliqué
 */
const generateComputedObject = ({ object, material }) => {
  if(isScenePaused) return;
  object.rotateX(-Math.PI * 0.5);
  const applyMaterial = (object) => {
    if (object.type === "Mesh") {
      object.material = material;
      object.castShadow = true; // Le cube projette des ombres
      object.receiveShadow = true;
    }
    object.children.forEach(applyMaterial);
  };

  applyMaterial(object);
  return object;
};

//#endregion

//#region Network
/**Cette méthode permet de récupérer les données de la face (objet 3D et material) en fonction des paramètres.
 * @param {any} params contient les paramètres de la face à charger
 * @param {any} face contient la face à charger
 * @returns {any} Renvoie la face générée par la méthode fetchFaceGltf, et le material généré par la méthode generateComputedMaterial
 */
const fetchFaceData = async (params, face) => {
  try {
    const materialInfos =
      "materialData" in params
        ? params.materialData
        : await fetchMaterialInfo(params.material);
    const [object, material] = await Promise.all([
      fetchFaceGltf(params, materialInfos, face),
      generateComputedMaterial(materialInfos, face),
    ]);

    return {
      object,
      material,
    };
  } catch (error) {
    console.error("Error fetching face data:", error);
    throw error;
  }
};
//#endregion Network

//#region GLTF Download

/**Cette méthode permet de setup de manière claire la requête pour récupérer les données de la face.
 * @param {any} params contient les paramètres de la face à charger
 * @param {any} materialInfos contient les informations du material à créer
 * @param {any} face contient la face à charger
 * @returns {any} Renvoie la réponse de la requête pour récupérer la face générée par l'OTS
 */
const getFetchFaceResponse = (params, materialInfos, face) => {
  const { signal } = params;
  if ("pattern" in params) {
    const queryStringified = queryString.stringify({
      sizeX: params.size.x,
      sizeY: params.size.y,
      sizeZ: params.size.z,
      pattern: params.pattern,
      bottom: params.bottom ? "true" : "false",
      textureSize: materialInfos.textureSize,
      face: face,
    });
    const faceUrl = `${process.env.REACT_APP_OTS_URL}/process/obj-face?${queryStringified}`;

    return fetch(faceUrl, { signal });
  } else {
    const queryStringified = queryString.stringify({
      type: params.type,
      sizeX: params.size.x,
      sizeY: params.size.y,
      sizeZ: params.size.z,
      bottom: params.bottom ? "true" : "false",
      textureSize: materialInfos.textureSize,
      renderType: params.renderType,
      cropStrategy: params.cropStrategy,
      face: face,
    });

    const faceUrl = `${process.env.REACT_APP_OTS_URL}/process/obj-face-pattern?${queryStringified}`;

    return fetch(faceUrl, {
      method: "POST",
      headers: {
        "Content-Type": "text/html",
      },
      body: params.patternData,
      signal,
    });
  }
};

/**Cette méthode s'occupe d'envoyer une requête pour récupérer les données de la face.
 * @param {any} params contient les paramètres de la face à charger
 * @param {any} materialInfos contient les informations du material à créer
 * @param {any} face contient la face à charger
 * @returns {any} Renvoie les données de la face générée par l'OTS
 */
const fetchFaceGltf = async (params, materialInfos, face) => {
  const response = await getFetchFaceResponse(params, materialInfos, face);

  if (response.body === null) {
    throw new Error("Empty response");
  } else {
    const buffer = await response.arrayBuffer();

    const loader = new GLTFLoader();

    return new Promise((resolve, reject) => {
      loader.parse(
        buffer,
        "",
        (gltf) => {
          resolve(gltf);
        },
        reject
      );
    });
  }
};

//#endregion GLTF Download

//#region Texture Download

/**Cette méthode permet de télécharger une texture depuis le backend.
 * @param {any} url contient l'url de la texture à télécharger
 * @returns {any} Renvoie la texture téléchargée
 */
const fetchTexture = (url, textureSize = 1) => {
  const loader = new TextureLoader();

  const texture = loader.load(url, undefined, undefined, (error) => {
    console.error('Error loading texture', error);
  });
  texture.wrapS = MirroredRepeatWrapping;
  texture.wrapT = MirroredRepeatWrapping;
  texture.alphaMap = null;

  return texture;
};

/**Cette méthode permet de récupérer les informations d'un material depuis le backend.
 * @param {any} materialId contient l'id du material à récupérer
 * @returns {any} Renvoie les informations du material
 */
export const fetchMaterialInfo = async (materialId) => {
  try {
    if (bearerToken === null) {
      bearerToken = await getBearerToken();
    }

    const response = await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/materials/search?id=${encodeURIComponent(materialId)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Material not found");
    }
    const materialData = await response.json();

    const material = {
          color: materialData.colorType === 'RAL'
          ? ralToHex(materialData.ralColorId, materialData, materialId)
          : computeHexNumber(materialData.color),             
      reflectivity: materialData.reflectivity,
      roughness: materialData.roughness,
      metalness: materialData.metalness,
      clearcoat: materialData.clearcoat,
      clearcoatRoughness: materialData.clearcoatRoughness,       
      textureSize: materialData.textureSize,
  
      colorMapUrl: materialData.colorType === 'TEXTURE' ? `${materialData.colorMapUrl}` : undefined,
    };
    
    return material;
  } catch (error) {
    console.error("Error fetching material info:", error);
    throw error;
  }
};

//#endregion Texture Download

//#region Auth

/**Cette méthode permet de récupérer un bearer token depuis le backend pour y effectuer des requêtes.
 * @returns {any} Renvoie le bearer token
 */
const getBearerToken = async () => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: process.env.REACT_APP_SERVICE_NAME,
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
    }),
  });
  return response.json().then((data) => data.accessToken);
};

//#endregion Auth

//#region Helpers

const computeHexNumber = (value) => {
  return Number(`0x${value}`);
};

const ralToHex = (ral) => {
  if (!ral || !colours[ral]) {
    return "#FFFFFF";
  } else {
    return colours[ral];
  }
};
//#endregion Helpers

export { ralToHex }; // Export de la méthode ralToHex
export default createModelContext;
