import queryString from "query-string";
import CreateThreeScene from "./renderer"
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { fetchMaterialInfo } from "./modelRenderer";

//#region waiter
const waiterCss = `
    .waiter-3d-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .waiter-3d,
    .waiter-3d:after {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
    .waiter-3d {
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -60px;
      margin-left: -60px;
      text-indent: -9999px;
      border-top: 10px solid rgba(204, 204, 204, 0.75);
      border-right: 10px solid rgba(204, 204, 204, 0.75);
      border-bottom: 10px solid rgba(204, 204, 204, 0.75);
      border-left: 10px solid rgba(90, 90,90, 1);
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: waiter-3d-animation 1.1s infinite linear;
      animation: waiter-3d-animation 1.1s infinite linear;
    }
    @-webkit-keyframes waiter-3d-animation {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes waiter-3d-animation {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }`;

const createWaiterStyle = () => {
    const createdStyleTag = document.createElement("style");
    createdStyleTag.textContent = waiterCss;
    document.body.appendChild(createdStyleTag);
};

const createWaiter = () => {
    const waiterWrapperDiv = document.createElement("div");
    waiterWrapperDiv.className = "waiter-3d-wrapper";
    const waiterDiv = document.createElement("div");
    waiterDiv.className = "waiter-3d";
    waiterWrapperDiv.appendChild(waiterDiv);
    return waiterWrapperDiv;
};
//#endregion waiter

//#region WebContext

const createWebContext = (content) => {
  const { setSize, addObjectToScene, start, updateMainMaterial, updatePlaceholderObject, pauseScene } = CreateThreeScene(content);

  if (!["fixed", "absolute", "relative"].includes(content.style.position)) {
      content.style.position = "relative";
  }

  setSize(content.clientWidth, content.clientHeight);
  const stopMonitoringSize = createContentSizeMonitor(content, setSize);

  start(() => {});

  const cleanup = () => {
    stopMonitoringSize();
  };


  let waiting = 0;
  createWaiterStyle();
  const waiter = createWaiter();

  /**
   * Cette méthode permet d'afficher un loader pendant le chargement de chaque face de l'objet 3D
   * @param {() => Promise<{object: THREE.Object3D, size: {x: number, y: number, z: number}}>} updater
   * @returns {Promise<boolean>}
   */
  const updateObject = async (updater) => {
    if (waiting === 0) {
      content.appendChild(waiter);
    }
    ++waiting;
    
    let rendered = false;
    let error = null;
    let result = null;
    try {
      result = await updater();
      
          rendered = result !== null;
      } catch (err) {
        error = err;
        console.error(err);
      }
      
      --waiting;
      if (waiting === 0) {
        content.removeChild(waiter);
      }
      
      if (error !== null) {
        throw error;
      }
      
      return rendered;
    };
    
    const updateMaterial = async (materialId) => {
      updateMainMaterial(materialId);
      return true
    };

  return {
    updateObject,
    updateMaterial,
    cleanup,
    updatePlaceholderObject,
    pauseScene
  };
  
};

//#endregion WebContext

//#region Responsive

const createContentSizeMonitor = (content, onSizeChange) => {
  const resizeObserver = new ResizeObserver(() => {
    const newWidth = content.clientWidth;
    const newHeight = content.clientHeight;
    onSizeChange(newWidth, newHeight);
  });

  resizeObserver.observe(content);

  return () => {
    resizeObserver.disconnect(); // Nettoyage lorsque le composant est démonté
  };
};


//#endregion

export default createWebContext;