import { Button } from "@mui/material";
import { styled } from "@mui/system";

const FormButtonPreviousStyled = styled(Button)({
  minWidth: "40%",
  color: "#ffffff",
  backgroundColor: "#007ac3",
  borderRadius: "50px",
  border: "1px solid #007ac3",
  transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
  textTransform: "none",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#ffffff",
    color: "#007ac3",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
  },
});

export const FormButtonPrevious = ({ onPrevStep, currentStep }) => {
  return (
    <FormButtonPreviousStyled onClick={() => onPrevStep(currentStep - 1)}>
      Précédent
    </FormButtonPreviousStyled>
  );
};
