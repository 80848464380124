export const fetchBearerToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: process.env.REACT_APP_SERVICE_NAME,
            apiKey: process.env.REACT_APP_BACKEND_API_KEY,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch bearer token");
      }

      const data = await response.json();
      return data?.accessToken;
    } catch (error) {
      console.error("Error fetching bearer token:", error);
    }
  };

  export const fetchPatterns = async (bearer) => {
    try {
      if (!bearer) return;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/patterns`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch patterns");
      }

      const resPatterns = await response.json();
      return resPatterns;
    } catch (error) {
      console.error("Error fetching patterns:", error);
    }
  };

  export const fetchAllTextures = async (bearer) => {
    try {
      if (!bearer) return;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/materials`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch textures");
      }

      const resTextures = await response.json();

      return resTextures;
    } catch (error) {
      console.error("Error fetching textures:", error);
    }
  };

  export const fetchTextures = async (bearer) => {
    try {
      if (!bearer) return;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/materials`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch textures");
      }

      const resTextures = await response.json();
      const formattedTextures = formatTextures(resTextures);

      return formattedTextures;
    } catch (error) {
      console.error("Error fetching textures:", error);
    }
  };

  export const fetchPrice = async (bearer, sizeX, sizeY, sizeZ, bottom, alu, corten) => {
    try {
      if (!bearer) return;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/price?sizeX=${encodeURIComponent(sizeX)}&sizeY=${encodeURIComponent(sizeY)}&sizeZ=${encodeURIComponent(sizeZ)}&bottom=${encodeURIComponent(bottom)}&alu=${encodeURIComponent(alu)}&corten=${encodeURIComponent(corten)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch price");
      }

      const resPrice = await response.json();
      return resPrice;
    } catch (error) {
      console.error("Error fetching price:", error);
    }
  };

  const formatTextures = (txs) => {
    const colors = {
      brillants: [],
      mats: [],
      métal: [],
      bois: [],
    };

    txs.forEach((tx) => {
      colors[tx.materialType].push(tx);
    });

    return [
      {
        id: "brillants",
        name: "Brillant",
        colors: colors.brillants,
      },
      {
        id: "mats",
        name: "Mat",
        colors: colors.mats,
      },
      {
        id: "metal",
        name: "Métal",
        colors: colors["métal"],
      },
      {
        id: "bois",
        name: "Bois",
        colors: colors.bois,
      },
    ];
  };